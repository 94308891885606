.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.staff-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #1d2026;
    border-radius: 10px;
    margin: 5px 0px;
    padding: 10px 40px;
}

.staff-row input{
    width: 150px;
    color: #000;
    border-radius: 10px;
    height: 30px;
    font-size: 14px;
}


.staff-row .button-container{
    height: 20px;
    width: 20px;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 10px;
    padding: 0px;
    background-color: red;
    position: relative;
    cursor: pointer;
}
.staff-row button{
    margin-top: -0px;
    border-radius: 10px;
    padding: 0px;
    background-color: transparent;
    line-height: 12px;
    position: absolute;
    font-size: 28px;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}
.staff-row .button-container:hover{
    background-color: #ff5a5a;
}
.arrows{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.arrow-btn{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    background-color: #282C34;
    border-radius: 15px;
}
.arrow-btn:hover{
    background-color: #3d4047;
}
.arrow-btn button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
    height: 100%;
}
.arrow-down{
    transform: rotate(180deg);
    margin-left: 0px !important;
    margin-top: -6px !important;
}
.staff-row .file {

    color: #fff;
    padding: 4px;
    height: 35px;
}
