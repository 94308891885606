main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}
.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
}
input{
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1.2rem;
    outline: 0;
    height: 34px;
    width: 100%;
    max-width: 278px;
}
button{
    padding: 10px;
    border-radius: 5px;
    font-size: 1.2rem;
    border: 0px;
    background-color: #3554c2;
    cursor: pointer;
    color: white;
    outline: 0;
    max-width: 300px;
    width: 100%;
    height: 44px;
    margin-top: 10px;
}
