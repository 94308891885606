.header{
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    color: #fff;
    width: 100%;
    border-bottom: 1px solid #595959;
}
.container{
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.nav-item{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 10px;
}
.nav-item:hover{
    background-color: #595959;
}
.active{
    text-underline: #fff;
    text-decoration: underline;
}
.logo{
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 10px;
}
