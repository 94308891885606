.location{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 40px;
    background-color: #1d2026;
    color: #fff;
    border-radius: 10px;
    padding: 0 20px;
    margin: 5px 0px;
}
.new-location{
    background-color: #1d2026;
    color: #fff;
    border: none;
    outline: none;
    font-size: 18px;
    width: 100%;
    margin: 5px 0px;
    height: 40px;
    border-radius: 10px;
    padding: 0px 20px;
    max-width: 100%;
}
.minus{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    background-color: #282C34;
    border-radius: 15px;
}
.minus-text{
    font-size: 34px;
    margin-top: -4px;
}
.location-container{
    margin-top:40px
}
.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
