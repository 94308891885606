.event-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background-color: #1d2026;
    border-radius: 10px;
    margin: 5px 0px;
}

.event-row input{
    width: 150px;
    color: #000;
    border-radius: 10px;
    height: 30px;
    font-size: 14px;
}
.event-container th{
    color: #fff;
    text-align: left;
    padding-left: 10px;
}
.event-container td{
    margin-right: 10px;
    color: #000;
}

.event-row select{
    width: 100px;
    border-radius: 10px;
    height: 30px;
    color: black;
    font-size: 14px;
}

.headers{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background-color: #1d2026;
    border-radius: 10px;
    margin: 5px 0px;
    position: sticky;
    top: 0px;
}

.headers div{
    width: 150px;
    font-size: 16px;
    padding: 0 10px;

}

.event-row.new {
    background-color: green;
}
.event-row .button-container{
    height: 20px;
    width: 20px;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 10px;
    padding: 0px;
    background-color: red;
    position: relative;
    cursor: pointer;
}
.event-row button{
    height: 10px;
    width: 10px;
    margin-top: -0px;
    border-radius: 10px;
    padding: 0px;
    background-color: transparent;
    line-height: 12px;
    position: absolute;
    font-size: 28px;
    top: 3px;
    left: 4px;
}
.event-row .button-container:hover{
    background-color: #ff5a5a;
}
.event-container{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}
.example-container{
    margin-top: 20px;
}
.example{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    background-color: #1d2026;
    border-radius: 10px;
    padding: 10px;
    width: 280px;
}
.example-left{
    display: flex;
    flex-direction: column;
}
.day{
    font-size: 11px;
    line-height: 10px;
    font-weight: bold;
}
.start-time{
    font-size: 16px;
    font-weight: 800;
}
.end-time{
    line-height: 10px;
    font-size: 14px;
    opacity: .8;
}
.example-center{

}
.example-right{
    margin-left: 20px;
}
.name{
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
}
.level-pill{
    font-size: 12px;
    font-weight: 700;
    padding: 2px 15px;
    border-radius: 10px;
    margin-left: 5px;
    height: 20px;
    background-color: #3554C2;
    color: #fff;
}
.round-name, .description {
    font-size: 12px;
    opacity: .8;
    line-height: 13px;
}
